.skills-section{
    background-color: var(--light-pink-background);
}

.skills-big-box{
    padding: 3rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-top-left-radius: 100px;
    border-bottom-right-radius:  50px;
    background-color: var(--light-green);
    margin: 4rem 1rem;
}

.skills-big-box p{
    text-align: center;
}

.skills-logos{
    display: flex;
    justify-content: space-between;
    width: 100%;
    opacity: 0.8;
    flex-wrap: wrap;
    margin-top: 2rem;
    position: relative;
}

.fading-skills{
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: var(--light-green);
    transition: all 2s ease-in-out;
}

#reveal-logos{
    width: 0%;
}

#language-logo {
    width: 80px;
}

@media screen and (max-width: 600px) {
    .skills-logos{
        justify-content: center;
        gap: 1rem;
    }
}