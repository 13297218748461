.about-section {
    padding: 5%;
    text-align: center;
    background-color: var(--light-pink-background);
}

.about-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
}

.about-left-box{
    width: 45vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.button-read-more {
    color: var(--white);
    border-radius: 5px;
    border: 0;
    padding: 0.5rem;
    width: 30%;
    background-color: var(--dark-green);
}

.about-container p {
    font-size: 1.2em;
    text-align: justify;
}

.about-container img {
    width: 25vw;
    position: relative;
    border-radius: 5px;
    opacity: 0;    
}

#reveal-picture{
    opacity: 1;
    transition: all 2s cubic-bezier(0, 0, 0.2, 1);;
}

.about-container img:before{
    content: '';
    background-image: url("../../img/flower1.svg");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 25em;
    height: 30em;
    z-index: 1;
    right: 10em;
}

.about-right-box{
    position:relative;
}

#flower-right{
    position: absolute;
    right: 10px;
}

@media screen and (max-width: 600px) {
    .about-container {
        flex-direction: column;
        gap: 1rem;
    }
    .about-left-box{
        width: 100%;
        align-items: center;
    }

    .about-container img {
        width: 55vw;
    }

    .about-container p {
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    #button-read-more{
        width: 90%;
    }

    
}