.main_menu ul {
    list-style-type: none; 
    display: flex;
    flex-direction: column; 
    gap: 3rem;  
    align-items: center;
}

.main_menu li {
    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
    line-height: 2.1rem;
    color: var(--light-pink-background);
    cursor: pointer;
}

.main_menu li:hover{
    opacity: 0.4;
    transition: all 0.2s ease-in-out;
}

.main_menu{
    background-color: var(--dark-pink);
    position: absolute;
    top: 0;
    min-height: 466px;
    width: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}