.welcome-section{
  width: 100%;
  background-image: url('../../img/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.main-container {
  padding: 16% 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}

.main-title{
  font-size: 4vw;
}
.main-title-h1 {
  color: var(--dark-green);
  font-size: 4vw;
  font-family:'Fredoka';
  font-weight: 100;
}

.third-row-wrapper{
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

#title-first-row{
  font-weight: 400;
  font-size: 2em;
}

#sub-title-main {
  font-size: 1.7vw;
  color: var(--dark-pink);
  margin-top: 1rem;
  font-weight: 2;
  animation: reveal 1s ease-in;
  animation-delay: 7s;
  animation-fill-mode: forwards;
  opacity: 0;
}

#title-second-row{
  margin-top: 10px;
}

#title-first-row span,
#title-second-row span,
#title-third-row span{
  position: relative;
}

#title-first-row span::after,
#title-second-row span::after,
#title-third-row span::after{
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-left: 2px solid transparent;
  background-color: var(--light-pink-background);
  
} 

#title-first-row span::after{
  animation: typing 1s steps(10, end);
  animation-fill-mode: forwards;
}

#title-second-row span::after{
  opacity: 1;
  animation: typing 1.5s steps(19, end);  
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

#title-third-row span::after{
  opacity: 1;
  animation: typing 1.3s steps(19, end);  
  animation-delay: 3.3s;
  animation-fill-mode: forwards;
}

#pam-svg{
  width: 10vw;
  height: 100%;
}

#pam-svg-p1{
  stroke-dasharray: 1099;
  animation: 2s showPam linear;
  animation-fill-mode: forwards;
  animation-delay: 5s;
  opacity: 0;
}

#pam-svg-p2{
  stroke-dasharray: 1099;
  animation: 2s showPam linear;
  animation-fill-mode: forwards;
  animation-delay: 5.4s;
  opacity: 0;
}

#pam-svg-a{
  stroke-dasharray: 1099;
  animation: 3s showPam linear;
  animation-fill-mode: forwards;
  animation-delay: 5.7s;
  opacity: 0;
}

#pam-svg-m{
  stroke-dasharray: 1099;
  animation: 3s showPam linear;
  animation-fill-mode: forwards;
  animation-delay: 6.3s;
  opacity: 0;
}

@keyframes typing{
  0%{
    border-left: 2px solid var(--dark-green);
  }
  99.9%{
    border-left: 2px solid var(--dark-green);
  }
  100%{
    width: 0;
    border: none;
  }
}

@keyframes reveal{
  100%{
    opacity: 1;
  }
}

@keyframes showPam {
  0%{
    stroke-dashoffset: 1099;
    opacity: 1;
  }
  100%{
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  #title-first-row{
    font-size:4em;
  }
  .welcome-section{
    background-image: url('../../img/background_mobile.svg');
    height: 100vh;
    width: 100vw;
  }

  .main-container{
    padding: 0 2rem;
    height: 100%;
  }

  .main-container h1 {
    font-size: 7vw;
    padding: 0 2rem;
  }

  .main-container p {
    font-size: 7.7vw;
    padding: 0 1vw;
  }

  #pam-svg{
    width: 17vw;
  }

  #sub-title-main{
    font-size: 4.7vw;
  }
}

@media screen and (max-width: 440px) {
  .third-row-wrapper{
    flex-direction: column;
    align-items: center;
    margin-top: 7px;
  }
}