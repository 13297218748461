.job-box-timeline{
    width: 85%;
    margin: 2rem 0;
    background-color: #fff;
    padding: 10px;
    border-radius: 7px;
}

.job-box-timeline h3 {
    font-weight: 600;
}

#reveal-job-box{
    transition: all 1s ease;
}

#hidden-job-box-left{
    opacity: 0;
    transform: translate(-60px, 50%);
}

#hidden-job-box-right{
    opacity: 0;
    transform: translate(60px, 50%);
}
