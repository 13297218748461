:root {
    --dark-green: #4B5842;
    --light-green: #ECEFE6;
    --medium-green: #AAB592;
    --dark-pink: #C4747C;
    --light-pink-background: #FAF9F7;
    --medium-pink-background: #F9F1F2;
    --white: #f8f8f8;
    --language-logo: #c4c4c4;
    --main-font: 'Arima', cursive;
    --secondary-font: 'Fredoka', sans-serif; 
}

.button-square {
    color: var(--dark-green);
    width: 20%;
    padding: 1rem;
    background-color: transparent;
    border: 2px solid var(--dark-green);
    cursor: pointer;
  }
  
.button-square:hover{
      color: var(--dark-pink);
      border-color: 3px solid var(--dark-pink);
}

h2, h3{
  font-family: var(--main-font);
}

p{
  font-family: var(--secondary-font);
  font-weight: 300;
}

button{
  font-family: var(--secondary-font);
  font-weight: 400;
}