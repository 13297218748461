.footer-section{
  background-color: var(--light-green);
}

footer {
  background-color: var(--medium-green);
  text-align: center;
  padding: 2rem;
  position: relative;
  z-index: 2;
}
.custom-shape-divider-bottom-1646369976 {  
  bottom: -5px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  background-color: var(--light-green);
  position: relative;
  z-index: 1;
}

.custom-shape-divider-bottom-1646369976 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 35px;
}

.custom-shape-divider-bottom-1646369976 .shape-fill {
  fill: var(--medium-green);
}

footer p {
  color: var(--white);
}
