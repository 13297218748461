.timeline-section{
    margin: 2rem 0;
    padding-top: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--medium-pink-background);
}

.timeline-container{
    justify-content: center;
    width: 800px;
    margin: 2rem 15rem; 
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /*grid-template-rows: repeat(7,1fr);*/
}

@media screen and (max-width: 950px) {
    .timeline-container {
        width: 80%;
        margin: 2rem 0;
    }
  }

  @media screen and (max-width: 600px) {
    .timeline-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem 0;
    }

    .job-box-timeline{
        z-index: 6;
        text-align: center !important;
        margin: 0;
        border: 2px solid var(--dark-green);
    }

    .date-timeline-box {
        margin: 2rem 0;
        background-color: var(--dark-pink);
        padding: 10px;
        z-index: 6;
        color: white;
    }

    .job-box-timeline::before {
        display: none;
    }
  }

.job-boxes-container{
    display: flex;
    flex-direction: column;
    padding: 6rem 3rem;
}

.vertical-line{
    position: absolute;
    z-index: 2;
    left: calc(50% - 1px);
    width: 2px;
    top: 0;
    bottom: 0;
    background-color: var(--dark-green);
}

.vertical-line::before,
.vertical-line::after {
    width: 1rem;
    height: 1rem;
    background-color: var(--medium-pink-background);
    content: '';
    position: absolute;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    border: 3px solid var(--dark-green);
    z-index: 3;
}

.vertical-line::after{
    bottom: 0;
}


/* ---------- STYLES FOR ANIMATION ----------*/

#timeline-date{
    opacity: 0;
}

#reveal-timeline-date{
    transition: all 2s ease-in-out;
}
