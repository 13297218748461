.projects-section {
  background-color: var(--light-pink-background);
  padding: 3rem 0;
  padding-top: 0;
}
.projects-container {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.projects-container h2 {
  text-align: center;
  color: var(--dark-green);
}

.arrows-container{
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40%;
  width: 100%;
  z-index: 1;
}

.arrows-container img {
  color: #fff;
  width: 100%;
}

.arrows-container button {
  width: 50px;
  height: 50px;
  background: none;
  cursor: pointer;
  border: none;
}


.project-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  gap: 5rem;
  margin: 0 10%;
  justify-content: center;
}

.center-button {
  display: flex;
  justify-content: center;
}

#button-more-projects {
  color: var(--dark-green);
  padding: 1rem;
  background-color: transparent;
  border: 2px solid var(--dark-green);
  cursor: pointer;
  border-radius: 4px;
}

#button-more-projects:hover{
    color: var(--dark-pink);
    border-color: 3px solid var(--dark-pink);
}

@media screen and (max-width: 650px) {
  .arrows-container{
  display: none;
  }
}
