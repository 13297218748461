.main-header {
    position: absolute;
    z-index: 4;
    height: 80%;
    width: 100%;
}

#logo-hamburguer {
    width: 10%;
}

#logo-x-close{
    width: 30px;
}

#button_hamburguer{
    display: flex;
    justify-content: space-between;  
    padding: 4%;    
    border: 0;
    background-color: transparent;
    cursor: pointer;
    z-index: 6;
    position: absolute;
}